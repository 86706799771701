import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect'
import LegacyFeatureJS from './legacyFeatureJQuery'

const useLegacyFeatures = (featureHeaderLoaded = true) => {
  useIsomorphicLayoutEffect(() => {
    if (featureHeaderLoaded) {
      LegacyFeatureJS.InitWhenReady()
    }

    return () => {
      if (featureHeaderLoaded) {
        LegacyFeatureJS.RemoveEventHandlers()
      }
    }
  }, [featureHeaderLoaded])
}

export default useLegacyFeatures
