import {
  variants,
  Detail,
  Section,
  Layout,
  Grid,
  Text,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Date from '@/components/generic/date'
import Ad from '@/components/generic/ad'
import Link from '@/components/generic/link'
import messages from '@/messages/magazine'
import trackingIds from '@/tracking/tracking-ids'
import {
  PersonColumn,
  DateColumn,
  CmsContentColumn,
} from '@/components/generic/detail-bar'
import {
  Areas,
  smallGridArea,
  mediumGridArea,
  largeGridArea,
} from './featureDetailBarGrid'

const FeatureDetailBar = ({ feature }) => {
  const intl = useIntl()

  return (
    <Section
      py={4}
      variant={variants.section.secondary}
      data-tracking-id={trackingIds.featureDetailBar}
    >
      <Layout>
        <Grid
          lCols={12}
          mCols={8}
          sCols={1}
          gridTemplateAreas={{
            s: smallGridArea(feature),
            m: mediumGridArea(feature),
            l: largeGridArea(feature),
          }}
        >
          <PersonColumn
            area={Areas.Words}
            title={intl.formatMessage(messages.words)}
            name={feature.author.name}
            imageUrl={feature.author.imageUrl}
            contributor={feature.author.contributor}
            username={feature.author.username}
          />

          <DateColumn
            area={Areas.Published}
            title={intl.formatMessage(messages.published)}
            date={feature.date}
            dateFormat={Date.Formats.MonthDayAndYear}
          />

          <CmsContentColumn
            area={Areas.PhotoCredit}
            title={intl.formatMessage(messages.photoCredit)}
            content={feature.photoCredit}
          />
        </Grid>
        <Detail
          title={intl.formatMessage(messages.pitchFeatureLinkTitle)}
          py={4}
        >
          <Text variant={variants.text.small}>
            {intl.formatMessage(messages.pitchFeatureLink, {
              pitchlink: (text) => (
                <Link
                  href="/pitch/feature"
                  variant={variants.text.small}
                  underline
                >
                  {text}
                </Link>
              ),
            })}
          </Text>
        </Detail>
        <Ad variant={Ad.variants.InPageHorizontal} />
      </Layout>
    </Section>
  )
}

FeatureDetailBar.propTypes = {
  feature: PropTypes.object,
}

export default FeatureDetailBar
