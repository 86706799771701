/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InnerHTML from 'dangerously-set-html-content' // Using this as Reacts dangerouslySetInnerHTML does not execute <script> tags
import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect'

import FeatureVideoHeader from '../feature-video-header'
import { HEADER_TYPES } from './getFeatureHeaderInfo'

const FeatureHeader = ({
  title,
  blurb,
  videoUrl,
  largeImageUrl,
  mobileImageUrl,
  headerType,
  srcSet,
  headerLoadComplete,
}) => {
  useIsomorphicLayoutEffect(() => {
    headerLoadComplete()
  }, [headerLoadComplete])

  return (
    <header>
      {videoUrl ? (
        <section>
          <FeatureVideoHeader
            title={title}
            videoUrl={videoUrl}
            mobileImageUrl={mobileImageUrl}
            largeImageUrl={largeImageUrl}
          />
        </section>
      ) : (
        <HeaderImage
          mobileImageUrl={mobileImageUrl}
          largeImageUrl={largeImageUrl}
          srcSet={srcSet}
          title={title}
          headerType={headerType}
        />
      )}
      {/* For Large & Video the blurb is with the content (featureStyleFactory.js). Otherwise it should be in the <header> */}
      {headerType !== HEADER_TYPES.Large &&
        headerType !== HEADER_TYPES.Video && (
          <p className="intro">
            <InnerHTML html={blurb} />
          </p>
        )}
    </header>
  )
}

FeatureHeader.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string,
  videoUrl: PropTypes.string,
  largeImageUrl: PropTypes.string,
  mobileImageUrl: PropTypes.string,
  headerType: PropTypes.string,
  srcSet: PropTypes.string,
  headerLoadComplete: PropTypes.func.isRequired,
}

// See EditorialHeaderVM.cshtml for legacy version of this
const HeaderImage = ({
  mobileImageUrl,
  largeImageUrl,
  srcSet,
  title,
  headerType,
}) => {
  if (headerType === HEADER_TYPES.Large) {
    return (
      <section className="featureSection headerContents">
        <section>
          <div className="image">
            <img src={largeImageUrl} alt={title} />
            {/* Not using srcset on <Img> here as it doesn't work as expected where Device Pixel Ratio > 1. CSS in featureStyle.scss will hide this on non-mobile */}
            {mobileImageUrl && (
              <img className="small" src={mobileImageUrl} alt={title} />
            )}
          </div>
        </section>
      </section>
    )
  }

  const Img = (
    <StyledImage
      src={mobileImageUrl}
      srcSet={srcSet}
      sizes="100vw"
      alt={title}
    />
  )
  if (headerType === HEADER_TYPES.Standard) {
    return <div className="large-image">{Img}</div>
  }

  return <div className="standard-image">{Img}</div>
}

HeaderImage.propTypes = {
  title: PropTypes.string.isRequired,
  mobileImageUrl: PropTypes.string,
  largeImageUrl: PropTypes.string,
  headerType: PropTypes.string,
  srcSet: PropTypes.string,
}

const StyledImage = styled.img`
  display: block;
  width: 100%;
`

export default FeatureHeader
