import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isMobile } from 'lib/checkScreenWidth'
import useBodyWidth from '@/hooks/useBodyWidth'
import Video from './Video'

const FeatureVideoHeader = ({
  title,
  mobileImageUrl,
  videoUrl,
  largeImageUrl,
}) => {
  const width = useBodyWidth()

  return isMobile(width) ? (
    <StyledImage src={mobileImageUrl} alt={title} />
  ) : (
    <div style={{ position: 'relative' }}>
      <OverlappingImage alt={title} src={largeImageUrl} />
      <Video videoUrl={videoUrl} />
    </div>
  )
}

FeatureVideoHeader.propTypes = {
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  mobileImageUrl: PropTypes.string.isRequired,
  largeImageUrl: PropTypes.string.isRequired,
}

const OverlappingImage = styled.img`
  position: absolute;
  width: 100%;
`

const StyledImage = styled.img`
  width: 100%;
`

export default FeatureVideoHeader
